import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './projectpage.css'

export default function ProjectPage() {
    const mounted = useRef(true)

    useEffect(() => {
        mounted.current = true

        return () => {
            mounted.current = false
        }
    })
    
    return (
       <>
            <div className='pp-main-container'>
                <div className='h-96 md:h-[640px] grid'>
                    <img className='col-start-1 col-span-1 row-start-1 row-span-1 h-auto w-full overflow-hidden object-cover object-bottom' src={require('../../Assets/Images/Alyssum Cup/banner.png')} alt="" />
                    <div className='col-start-1 col-span-1 row-start-1 row-span-1 bg-gradient-to-b from-black via-transparent to-black opacity-25'></div>
                </div>
                <div className='default-main'>
                    <div className='lg:ml-6 mr-6 lg:mr-96 p-4 md:pl-16 bg-teal-200 -translate-y-1/2'>
                        <h4 className='font-space font-normal'>Alyssum Cup</h4>
                        <p className='font-ibmplex-mono text-xs mt-4 w-1/2 uppercase'>AN OSU! COMMUNITY TOURNAMENT</p>
                    </div>
                    <div className='flex flex-col'>
                        <div className='max-w-5xl md:grid md:grid-cols-2 justify-start self-center my-36'>
                            <div className='section-header p-4 flex flex-col'>
                                <h1 className='font-recoleta text-5xl tracking-wide border-l border-l-[#dc6d1c] pl-3'>Become the Champion.</h1>
                                <p className='font-ibmplex-mono text-xs mt-4 w-1/3 uppercase'>BRAND CONTENT</p>
                            </div>
                            <div className='section-body p-4'>
                                <p className='font-space text-base md:text-xl'>A community tournament for an online rhythm game, osu!, hosted by myself. The tournament is an international rank restricted tournament that aims to have a competitive experience for very skilled top-players. </p>
                            </div>
                        </div>
                        <div className='section-img my-10 bg-[#dc6d1c] py-36 w-full flex flex-col items-center'>
                            <div className='px-0 md:px-4 max-w-7xl'>
                                <img className='md:rounded-md' src={require('../../Assets/Images/Alyssum Cup/als-collection.png')} alt="" />
                                <p className='font-space text-base py-2 text-right text-white text-opacity-50 md:text-xl'>Alyssum Cup 2022</p>
                            </div>
                        </div>
                        <div className='section-body p-4 max-w-5xl self-center my-36'>
                            <div className='md:grid md:grid-cols-2'>
                                <h4 className='font-recoleta tracking-wide font-normal mb-14 border-l border-l-[#dc6d1c] pl-3 h-fit'>Create an Exciting Experience for Both the Players and the Viewers.</h4>
                                <div className='flex flex-col gap-y-2'>
                                    <p className='font-space text-base mt-4 md:mt-0 md:text-xl'>Most matches, and all of pre-edited mappool showcases, have been broadcasted over at Twitch. The overlay for the broadcasts are made to be semi interactive (from the streamer's POV). See a short preview of a nail-biting grand-finals match between "scylla" and "Nightlywind" down below.</p>
                                    <p className='font-space text-base mt-4 md:mt-0 md:text-xl'>Note: Gameplay elements are not part of this project.</p>
                                </div>
                            </div>
                        </div>
                        <div className='section-img my-10 bg-[#dc6d1c] flex flex-col items-center gap-y-12 py-36 '>
                            <div className='px-0 md:px-4 max-w-7xl'>
                                <img className='md:rounded-md' src={require('../../Assets/Images/Alyssum Cup/alyssum-cup-showcase.gif')} alt="" />
                            </div>
                            <div className='px-0 md:px-4 max-w-7xl'>
                                <img className='md:rounded-md' src={require('../../Assets/Images/Alyssum Cup/mappool-showcase.png')} alt="" />
                            </div>
                        </div>
                        <div className='section-contact pb-20 bg-teal-200 p-4 mr-6 translate-y-1/2'>
                            <p className='font-ibmplex-mono text-xs'>WHAT DID YOU THINK?</p>
                            <h4 className='font-recoleta tracking-wide font-normal mt-12'>Liked what you saw?</h4>
                            <Link to="/about">
                                <h4 className='font-recoleta tracking-wide font-normal underline underline-offset-4 decoration-1 mt-2'>Hit me up.</h4>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='w-full mt-44'>
                    
                </div>
            </div>
       </>
    )
}
