import React, { useEffect, useRef } from 'react'
import Card from './ProjectCard'
import './projectcard.css'

export default function ProjectCardContainer() {
    const mounted = useRef(false)
    const cardContainer = useRef()

    function widthIsLessThan(width) {
		if (window.innerWidth < width) {
			return true
		}

		return false
	}
    
    useEffect(() => {
        const containerWidth = cardContainer.current.offsetWidth
        const cardWidth = cardContainer.current.firstChild.clientWidth
        const offsetContainerWidth = containerWidth - cardWidth
        const maxPercentage = (offsetContainerWidth / containerWidth) * 100
        let prevPercentage = 0
        let mouseDown = false
        let startX
        let cardOffsetCalculation = window.innerWidth / 2 - cardWidth / 2
        mounted.current = true
        
        cardContainer.current.style.transform = `translateX(${cardOffsetCalculation}px)`
        
        cardContainer.current.onmousemove = (event) => {
            const deltaX = startX - event.clientX
            const endX = window.innerWidth / 2
            
            const percentage = (deltaX / endX) * -100
            const nextPercentage = prevPercentage + percentage

            if (mouseDown) {
                const offSetNextPercentage = Math.max(Math.min(nextPercentage, 0), -maxPercentage)

                cardContainer.current.animate({
                    transform: `translateX(calc(${cardOffsetCalculation}px + ${offSetNextPercentage}%))`
                }, {duration: 1500, fill: 'forwards'})

                window.onmouseup = (event) => {
                    mouseDown = !mouseDown
                    prevPercentage = offSetNextPercentage
                }
            }

            window.onmousedown = (event) => {
                mouseDown = !mouseDown
                startX = event.clientX
            }
            
        }

        cardContainer.current.ontouchstart = (event) => {
            startX = event.touches[0].clientX
            const endX = window.innerHeight
            
            window.ontouchmove = (event) => {
                const deltaX = startX - event.touches[0].clientX
                
                const percentage = (deltaX / endX) * -100
                const nextPercentage = prevPercentage + percentage
                const offSetNextPercentage = Math.max(Math.min(nextPercentage, 0), -maxPercentage)
                
                cardContainer.current.animate({
                    transform: `translateX(calc(${cardOffsetCalculation}px + ${offSetNextPercentage}%))`
                }, {duration: 1500, fill: 'forwards'})
                
                window.ontouchend = (event) => {
                    console.log(percentage)
                    prevPercentage = offSetNextPercentage
                }
            }
        }
        
        return () => {
            mounted.current = false
        }
    })
    
    
    return (
        <div ref={cardContainer} className='card-container'>
            <Card title='Alyssum Cup' img={require('../../Assets/Images/Alyssum Cup/logo.png')} urlParams='alyssum-cup'/>
            <Card title='The Two-Headed Raven' img={require('../../Assets/Images/The Two Headed Raven/banner.png')} urlParams='the-two-headed-raven'/>
        </div>
    )
}
