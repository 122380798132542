import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './projectpage.css'

export default function ProjectPage() {
    const mounted = useRef(true)

    useEffect(() => {
        mounted.current = true

        return () => {
            mounted.current = false
        }
    })
    
    return (
       <>
            <div className='pp-main-container'>
                <div className='h-96 md:h-[640px] grid'>
                    <img className='col-start-1 col-span-1 row-start-1 row-span-1 h-auto w-full overflow-x-hidden object-cover' src={require('../../Assets/Images/Experimental Portfolio/banner.png')} alt="" />
                    <div className='col-start-1 col-span-1 row-start-1 row-span-1 bg-gradient-to-b from-black via-transparent to-black opacity-25'></div>
                </div>
                <div className='default-main'>
                    <div className='lg:ml-6 mr-6 lg:mr-96 p-4 md:pl-16 bg-teal-200 -translate-y-1/2'>
                        <h4 className='font-space font-normal'>Experimental Portfolio</h4>
                        <p className='font-ibmplex-mono text-xs mt-4 w-1/2 uppercase'>A School Project</p>
                    </div>
                    <div className='flex flex-col'>
                        <div className='max-w-5xl md:grid md:grid-cols-2 justify-start self-center my-36'>
                            <div className='section-header p-4 flex flex-col'>
                                <h1 className='font-recoleta text-5xl tracking-wide border-l border-l-[#D34BFD] pl-3'>A Valuable Experiment.</h1>
                                <p className='font-ibmplex-mono text-xs mt-4 w-1/3 uppercase'>Web Development</p>
                            </div>
                            <div className='section-body p-4'>
                                <p className='font-space text-base md:text-xl'>This is a project ignited by a Web Development Fundamentals class at school. I had attempted to create a portfolio website, which also would have to fulfill the requirements of the school projects.</p>
                            </div>
                        </div>
                        <div className='section-img my-10 bg-[#D34BFD] py-36 w-full flex flex-col items-center'>
                            <div className='px-0 md:px-4 max-w-7xl '>
                                <img className='md:rounded-md' src={require('../../Assets/Images/Experimental Portfolio/1.png')} alt="" />
                                <p className='font-space text-base py-2 text-right text-white text-opacity-50 md:text-xl'>Experimental Portfolio 2022</p>
                            </div>
                        </div>
                        <div className='section-body p-4 max-w-5xl self-center my-36'>
                            <div className='md:grid md:grid-cols-2'>
                                <h4 className='font-recoleta tracking-wide font-normal mb-14 border-l border-l-[#D34BFD] pl-3 h-fit'>Overcoming Limitations</h4>
                                <div className='flex flex-col gap-y-2'>
                                    <p className='font-space text-base mt-4 md:mt-0 md:text-xl'>A quick realization that this would not turn out to be a typical portfolio website kicked in early on, but I decided to march on and kept going with the project.</p>
                                    <p className='font-space text-base mt-4 md:mt-0 md:text-xl'>In the end, I end up with a website that I am relatively proud of, but it wasn't what I imagined my portfolio website to be like. It wasn't my portfolio. This is why you are here instead.</p>
                                </div>
                            </div>
                        </div>
                        <div className='section-img my-10'>
                            <div className='py-36 bg-[#D34BFD] flex flex-col items-center'>
                                <div className='px-0 md:px-4 max-w-7xl flex flex-col gap-y-12'>
                                    <img className='md:rounded-md' src={require('../../Assets/Images/Experimental Portfolio/2.png')} alt="" />
                                    <img className='md:rounded-md' src={require('../../Assets/Images/Experimental Portfolio/3.png')} alt="" />
                                    <img className='md:rounded-md' src={require('../../Assets/Images/Experimental Portfolio/4.png')} alt="" />
                                    <img className='md:rounded-md' src={require('../../Assets/Images/Experimental Portfolio/5.png')} alt="" />
                                    <img className='md:rounded-md' src={require('../../Assets/Images/Experimental Portfolio/6.png')} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='section-contact pb-20 bg-teal-200 p-4 mr-6 translate-y-1/2'>
                            <p className='font-ibmplex-mono text-xs'>WHAT DID YOU THINK?</p>
                            <h4 className='font-recoleta tracking-wide font-normal mt-12'>Liked what you saw?</h4>
                            <Link to="/about">
                                <h4 className='font-recoleta tracking-wide font-normal underline underline-offset-4 decoration-1 mt-2'>Hit me up.</h4>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='w-full mt-44'>
                    
                </div>
                <a className='rounded-full bg-[#020205] aspect-square w-24 fixed bottom-3 right-3 rotate-6' target='_blank' href='https://web-dev-portfolio-soik.herokuapp.com' rel="noreferrer">
                    <div className='flex justify-center items-center w-full h-full text-xs font-ibmplex-mono uppercase'>
                        See Live
                    </div>
                </a>
            </div>
       </>
    )
}
