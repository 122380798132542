import React from 'react'
import './projects.css'

// Components
import ProjectListingCard from '../../Components/ProjectListingCard/ProjectListingCard'

// Data
import featuredProjects from '../../projects-data'

export default function Projects() {

    return (
        <>
            <div className='project-main-container'>
                <div className='project-list-header'>
                    <div className='page-header-wrapper'>
                        <span className='page-header self-start'>Stuff</span>
                        <span className='page-header self-end'>That I Did.</span>
                    </div>
                </div>
                <div className='project-list-main'>
                    <div className='project-list-container'>
                        {featuredProjects.map((project, index) => {
                            return (
                                <ProjectListingCard 
                                key={index} 
                                id={project.id} 
                                title={project.title}
                                kebabTitle={project.urlParam}
                                description={project.description}
                                credit={project.credit}
                                theme={project.themeColor}
                                thumbnail={project.thumbnail} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}
