import React from 'react'
import './home.css'

// Components
import ProjectCards from '../../Components/ProjectCard/ProjectCardContainer';

export default function Home() {
  return (
    <div className='home-bg'>
        <ProjectCards />
    </div>
  )
}
