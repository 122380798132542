import React from 'react'
import { Link } from 'react-router-dom'
import { useRef, useEffect } from 'react'

export default function ProjectListingCard(props) {
    const mounted = useRef(false)
    const themedText = useRef()

    useEffect(() => {
        mounted.current = true  

        themedText.current.style.color = props.theme

        return () => {
            mounted.current = false
        }
    })

    return (
        <Link className='project-listing-card' to={`/projects/${props.kebabTitle}`}>
            <div id={props.id} className='project-listing-card-container'>
                <div className='lc-description'>
                    <span ref={themedText} className='col-span-2'>{props.description}</span>
                    <span className='col-start-4 col-span-3 text-right'>{props.credit}</span>
                </div>
                <div className='lc-thumbnail-container'>
                    <img className='card-thumbnail' src={require('../../Assets/Images/' + props.thumbnail)} alt="" />
                </div>
                <span className='lc-title'>{props.title}</span>
            </div>
        </Link>
    )
}
