import React from 'react'
import './about.css'
import { Link } from 'react-router-dom'

// Components
import ProjectListingCard from '../../Components/ProjectListingCard/ProjectListingCard'

// Data
import featuredProjects from '../../projects-data'

export default function Projects() {

    return (
        <>
            <div className='about-main-container'>
                <div className='about-list-header'>
                    <div className='page-header-wrapper'>
                        <span className='page-header self-start'>Get To</span>
                        <span className='page-header self-end text-right'>Know Me</span>
                    </div>
                </div>
                <div className='about-list-main text-black'>
                    <div className='section-header my-36 p-4 max-w-5xl md:grid md:grid-cols-2 justify-start self-center'>
                        <h1 className='font-recoleta tracking-wide font-normal h-fit mb-4 border-l border-l-[#b42d2d] pl-3'>Hey there!</h1>
                        <p className='font-space text-base md:text-xl'>On the internet, I'm known as 'soik' or 'Saika'. But over in the real world, I'm more known as Khetdan, although I'd much rather you call me by my nickname 'Kate'. I am also a proud owner of the cutest cat ever.</p>
                    </div>
                    <div className='section-img py-36 px-2 bg-[#b42d2d] flex flex-col items-center'>
                        <div className='max-w-md'>
                            <img className='rounded-lg' src={require('../../Assets/Images/20230205_010212.jpg')} alt="" />
                            <p className='font-space text-base py-2 text-right text-white text-opacity-50 md:text-xl'>This is my cat Sasha</p>
                        </div>
                    </div>
                    <div className='section-body my-36 max-w-5xl p-4 self-center'>
                        <div className='md:grid md:grid-cols-2'>
                            <h4 className='font-recoleta tracking-wide font-normal h-fit mb-4 border-l border-l-[#b42d2d] pl-3'>What I do</h4>
                            <p className='font-space text-base mb-4 md:text-xl'>Currently, I am a Graphic Design and Web Development (aka New Media Design) over at Jönköping University. Specialized in front-end development, graphic design, illustrations, and baking!</p>
                        </div>
                    </div>
                    <div className='section-knowledge p-4 bg-teal-200 py-20 mb-36 w-full flex flex-col items-center'>
                        <h4 className='font-recoleta tracking-wide font-normal mb-12 pl-3'>My abilities</h4>
                        <div className='2-col grid max-w-5xl grid-cols-2 grid-rows-1 mb-12'>
                            <p className='font-space text-base text-right pr-12 md:text-xl'>Technologies</p>
                            <div className='font-ibmplex-mono flex flex-col gap-y-2'>
                                <p className='text-base md:text-xl'>Javascript ES6</p>
                                <p className='text-base md:text-xl'>RESTful API</p>
                                <p className='text-base md:text-xl'>Node.JS</p>
                                <p className='text-base md:text-xl'>tailwindcss</p>
                                <p className='text-base md:text-xl'>React</p>
                                <p className='text-base md:text-xl'>Vue</p>
                            </div>
                        </div>
                        <div className='2-col max-w-5xl grid grid-cols-2 grid-rows-1'>
                            <p className='font-space text-base text-right pr-12 md:text-xl'>Softwares</p>
                            <div className='font-ibmplex-mono flex flex-col gap-y-2'>
                                <p className='text-base md:text-xl'>Clip Studio Paint</p>
                                <p className='text-base md:text-xl'>Adobe Photoshop</p>
                                <p className='text-base md:text-xl'>Adobe Illustrator</p>
                                <p className='text-base md:text-xl'>Adobe Premiere Pro</p>
                                <p className='text-base md:text-xl'>Adobe After Effects</p>
                                <p className='text-base md:text-xl'>Adobe XD</p>
                                <p className='text-base md:text-xl'>Figma</p>
                            </div>
                        </div>
                    </div>
                    <div className='section-contact pb-20 bg-teal-200 p-4 mr-6 translate-y-1/2'>
                        <p className='font-ibmplex-mono text-xs'>WHAT DID YOU THINK?</p>
                        <h4 className='font-recoleta tracking-wide font-normal mt-12'>Liked what you saw?</h4>
                        <Link to="/contact">
                            <h4 className='font-recoleta tracking-wide font-normal underline underline-offset-4 decoration-1 mt-2'>Hit me up.</h4>
                        </Link>
                    </div>
                </div>
                
            </div>
            <div className='w-full mt-44'>
                    
            </div>
        </>
    )
}
