import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './projectcard.css'

export default function ProjectCard(props) {
    const mounted = useRef(false)
    const tiltHover = (x, y) => `perspective(300px) rotateY(${x}deg) rotateX(${y}deg)`

    useEffect(() => {
        mounted.current = true
        const cardContainer = document.getElementsByClassName('card-container')[0]
        
        for (const card of cardContainer.children) {
            const w = card.clientWidth
            const h = card.clientHeight

            // console.log(card.getBoundingClientRect().x)
            
            card.onmousemove = (event) => {
                const offsetX = card.getBoundingClientRect().x
                const x = event.clientX - offsetX
                const y = event.clientY
                const c = 7
                
                const xRot = c * ((x - w / 2) / w)
                const yRot = -c * ((y - h / 2) / h)
                
                // console.log(card)
                
                card.style.transform = `${tiltHover(xRot, yRot)} scale(1.05)`
                card.style.transition = '200ms linear'
            }
            
            card.onmouseout = (event) => {
                card.style.transform = `${tiltHover(0, 0)} scale(1.0)`
                card.style.transition = '700ms ease-in-out'
            }
        }
        
        return () => {
            mounted.current = false
        }
    })

    return (
        <div className='project-card-container'>
            <div className='showcase-card group peer window-shape c-overlay' draggable='false'>
                <Link className='grid' to={`/projects/${props.urlParams}`} draggable='false'>
                    <div className='c-overlay w-full h-full overlay-gradient window-shape z-20' draggable='false'></div>
                    <img className='project-card-img c-overlay window-shape object-bottom' src={props.img} alt="" />
                </Link>
            </div>
            <div className='project-card-title c-overlay'>
                <h2>{props.title}</h2>
            </div>
        </div>
    )
}
