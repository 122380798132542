import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7uMDOdSmMmvNJ5cJCAUoQei6Y0hXZjdU",
  authDomain: "online-portfolio-soik.firebaseapp.com",
  projectId: "online-portfolio-soik",
  storageBucket: "online-portfolio-soik.appspot.com",
  messagingSenderId: "638509909355",
  appId: "1:638509909355:web:7d7db1a413826482d8b82e",
  measurementId: "G-TWB5R3SQK5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
