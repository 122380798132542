import './input.css'
import {Route, Routes} from 'react-router-dom'

// Pages 
import AlyssumCup from './Pages/ProjectPage/AlyssumCup'
import TheTwoHeaderRaven from './Pages/ProjectPage/TheTwoHeaderRaven'
import ExperimentalPortfolio from './Pages/ProjectPage/ExperimentalPortfolio'
import NewEpsilon from './Pages/ProjectPage/NewEpsilon'
import ProjectsListing from './Pages/Projects/Projects'
import HomePage from './Pages/Home/Home'
import AboutMe from './Pages/AboutMe/AboutMe'
import ContactMe from './Pages/ContactMe/ContactMe'

// Components
import Navbar from './Components/Navbar/Navbar';

function App() {
	return (
		<>
			<Navbar />
			<Routes>
				<Route path='/' element={<HomePage />}/>
				<Route path='/about' element={<AboutMe />}/>
				<Route path='/contact' element={<ContactMe />}/>
				<Route path='/projects' element={<ProjectsListing />}/>
				<Route path='/projects/alyssum-cup' element={<AlyssumCup />} />
				<Route path='/projects/the-two-headed-raven' element={<TheTwoHeaderRaven />} />
				<Route path='/projects/experimental-portfolio' element={<ExperimentalPortfolio />} />
				<Route path='/projects/new-epsilon' element={<NewEpsilon />} />
			</Routes>
		</>
	)
}

export default App;
