import React, {useRef, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import { ReactComponent as Logo } from '../../Assets/logo-w.svg'

export default function InteractiveFooter() {
	const [changeHamColor, setHamColor] = useState(false)
	const [changeLogoColor, setLogoColor] = useState(false)
	const mounted = useRef(false)
	const mobileBtnsContainer = useRef()
	const check = useRef()
	const hamburgerIcon = useRef()
	const exitIcon = useRef()
	const logoPaths = document.getElementsByClassName('cls-1')
	const navBtns = document.getElementsByClassName('btn-animate')
	const iconLines = document.getElementsByClassName('icon-lines')

	function isPastScrollThreshold() {
		if (window.scrollY > 285) {
			return true
		}

		return false
	}

	function widthIsLessThan(width) {
		if (window.innerWidth < width) {
			return true
		}

		return false
	}
	
	function changeNavStyle() {
		if (check.current.checked) {
			return
		}

		if (isPastScrollThreshold()) {
			setHamColor(true)
			setLogoColor(true)
		} else {
			setHamColor(false)
			setLogoColor(false)
		}
	}

	useEffect(() => {
		mounted.current = true

		function openNavMenu() {		
			mobileBtnsContainer.current.style.transform = widthIsLessThan(1024) ? 'translateX(0%)' : 'translateX(50%)'
			mobileBtnsContainer.current.style.transition = 'transform 400ms ease-in-out'
	
			for (const btn of navBtns) {
				btn.style.transition = `700ms ease-in-out ${50 + 100 * btn.id}ms`
				btn.style.opacity = 1
				btn.style.transform = 'translateY(0%)'
			}
		}
		
		function closeNavMenu() {			
			mobileBtnsContainer.current.style.transform = 'translateX(100%)'
			mobileBtnsContainer.current.style.transition = 'transform 400ms ease-in-out'
	
			for (const btn of navBtns) {
				btn.style.transition = `0ms 350ms`
				btn.style.opacity = 0
				btn.style.transform = 'translateY(100%)'
			}
		}

		if (changeLogoColor) {
			for (const path of logoPaths) {
				path.style.transition = 'fill 300ms ease'
				path.style.fill = '#000'
			}
		} else {
			for (const path of logoPaths) {
				path.style.transition = 'fill 300ms ease'
				path.style.fill = '#fff'
			}
		}

		if (changeHamColor) {
			for (const line of iconLines) {
				line.style.backgroundColor = '#000'
			}
		} else {
			for (const line of iconLines) {
				line.style.backgroundColor = '#fff'
			}
		}

		check.current.onclick = (event) => {
			hamburgerIcon.current.classList.toggle('invisible')
			exitIcon.current.classList.toggle('invisible')

			if (check.current.checked) { 
				openNavMenu()

				if (widthIsLessThan(1024)) {
					setLogoColor(true)
				} else {
					if (isPastScrollThreshold()) {
						setLogoColor(true)
					} else {
						setLogoColor(false)
					}
				}

				setHamColor(false)
			} else { 
				closeNavMenu()

				if (widthIsLessThan(1024)) {
					if (isPastScrollThreshold()) {
						setLogoColor(true)
					} else {
						setLogoColor(false)
					}
				} else {
					if (isPastScrollThreshold()) {
						setLogoColor(true)
					} else {
						setLogoColor(false)
					}
				}

				if (isPastScrollThreshold()) {
					setHamColor(true)
				} else {
					setHamColor(false)
				}
			}
		}

		window.addEventListener('scroll', changeNavStyle)

		for (const btn of navBtns) {
			btn.addEventListener('click', (event) => {
				if (check.current.checked) {
					check.current.checked = !check.current.checked
				}
				hamburgerIcon.current.classList.toggle('invisible')
				exitIcon.current.classList.toggle('invisible')
				closeNavMenu()
				setHamColor(false)
			})
		}

		return () => {
			mounted.current = false
			window.removeEventListener('scroll', changeNavStyle)
		}
	}) 

	return (
		<>
			<div className='nav-main z-20'>
				<div className='nav-container-mobile'>
					<div className='nav-icon-container'>
						<Link to="/">
							<Logo />
						</Link>
					</div>
					<div className='nav-check-container'>
						<div className='nav-check'>
							<input ref={check} className='overlay hidden-check' type="checkbox" name="navButton" id="footer-check" />
							<div className='overlay'>
								<div className='nav-toggle-btns'>
									<div ref={hamburgerIcon} className='hamburger-icon overlay'>
										<div className="hamburger-line-1 icon-lines"></div>
										<div className="hamburger-line-2 icon-lines"></div>
									</div>
									<div ref={exitIcon} className='exit-icon overlay invisible'>
										<div className='exit-line-1 icon-lines'></div>
										<div className='exit-line-2 icon-lines'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div ref={mobileBtnsContainer} className='mobile-btn-container flex flex-col items-start lg:justify-center w-full z-10'>
				<div className='fixed bottom-0 mb-12 lg:mb-0 lg:bottom-auto flex flex-col gap-y-20 p-2'>
					<section className='grid grid-cols-2 px-4'>
						<span className='nav-btn-mono mt-[16px]'>Menu</span>
						<div className='mobile-menu-container'>
							<Link id='0' className='nav-btn btn-animate' to="/">Home</Link>
							<Link id='1' className='nav-btn btn-animate' to="/about">About</Link>
							<Link id='2' className='nav-btn btn-animate' to="/projects">Projects</Link>
							<Link id='3' className='nav-btn btn-animate' to="/contact">Contact</Link>
						</div>
					</section>
					<section className='grid grid-cols-2 px-4'>
						<span className='nav-btn-mono mt-[2px]'>Follow Me</span>
						<div className='mobile-menu-container'>
							<a id='3' className='nav-btn-mono btn-animate' rel="noreferrer" target="_blank" href="https://twitter.com/soik_art">Twitter</a>
							<a id='4' className='nav-btn-mono btn-animate' rel="noreferrer" target="_blank" href="https://github.com/soikers">Github</a>
							<a id='5' className='nav-btn-mono btn-animate' rel="noreferrer" target="_blank" href="https://www.instagram.com/saika0k1/?theme=dark">Instagram</a>
							<a id='6' className='nav-btn-mono btn-animate' rel="noreferrer" target="_blank" href="https://www.behance.net/khetdanphopat">Behance</a>
						</div>
					</section>
				</div>
			</div>
		</>
	)
}
