import React, { useRef } from 'react'
import './contact.css'
import emailjs from 'emailjs-com'

// Components
import ProjectListingCard from '../../Components/ProjectListingCard/ProjectListingCard'

// Data
import featuredProjects from '../../projects-data'

export default function Projects() {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ldbxo0l', 'template_htvh6zg', form.current, '1c7l9XZeIAs2N7F7W')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            form.current.reset()
    };

    return (
        <>
            <div className='contact-main-container'>
                <div className='contact-list-header'>
                    <div className='page-header-wrapper'>
                        <span className='page-header self-start'>Reach out</span>
                        <span className='page-header self-end'>to me.</span>
                    </div>
                </div>
                <div className='contact-list-main text-black py-36'>
                    <div className='w-full flex justify-center'>
                        <div className='contact-form m-4 bg-teal-200 grow p-4 max-w-2xl'>
                            <p className='font-ibmplex-mono text-xs uppercase mb-8'>Contact Form</p>
                            <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-y-4'>
                                <input className='bg-transparent w-full font-space text-sm border-b border-b-black p-2 outline-none' type="text" name="from_name" id="name" placeholder='Name' />
                                <input className='bg-transparent w-full font-space text-sm border-b border-b-black p-2 outline-none'  type="text" name="from_email" id="name" placeholder='Email Address' />
                                <textarea className='bg-transparent w-full h-40 font-space text-sm border-b border-b-black p-2 outline-none'  name="message" id="name" placeholder='Your Message' />
                                <button className='aspect-square w-24 rounded-full bg-pink-500 font-ibmplex-mono text-sm text-white -rotate-6 self-end mt-4'>Send!</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
