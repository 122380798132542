import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './projectpage.css'

export default function ProjectPage() {
    const mounted = useRef(true)

    useEffect(() => {
        mounted.current = true

        return () => {
            mounted.current = false
        }
    })
    
    return (
       <>
            <div className='pp-main-container'>
                <div className='h-96 md:h-[640px] grid'>
                    <img className='col-start-1 col-span-1 row-start-1 row-span-1 h-auto w-full overflow-x-hidden object-cover' src={require('../../Assets/Images/New Epsilon/top-only.png')} alt="" />
                    <div className='col-start-1 col-span-1 row-start-1 row-span-1 bg-gradient-to-b from-black via-transparent to-black opacity-25'></div>
                </div>
                <div className='default-main'>
                    <div className='lg:ml-6 mr-6 lg:mr-96 p-4 md:pl-16 bg-teal-200 -translate-y-1/2'>
                        <h4 className='font-space font-normal'>New Epsilon</h4>
                        <p className='font-ibmplex-mono text-xs mt-4 w-1/2 uppercase'>In Collaboration With Filip G. and Phong T.</p>
                    </div>
                    <div className='flex flex-col'>
                        <div className='max-w-5xl md:grid md:grid-cols-2 justify-start self-center my-36'>
                            <div className='section-header p-4 flex flex-col'>
                                <h1 className='font-recoleta text-5xl tracking-wide border-l border-l-[#E8B54F] pl-3'>Travel the Universe.</h1>
                                <p className='font-ibmplex-mono text-xs mt-4 w-1/3 uppercase'>Front-end Development</p>
                            </div>
                            <div className='section-body p-4'>
                                <p className='font-space text-base md:text-xl'>New Epsilon is a space traveling web application. Visit planets and moons of our solar system. You can take a visit to the floating cities of Venus, a former hostile and deadly planet of our solar system; or a winter holiday trip to Europa, one of Jupiter's moons. The solar system is the limit!</p>
                            </div>
                        </div>
                        <div className='section-img my-10 flex flex-col gap-y-4'>
                            <img src={require('../../Assets/Images/New Epsilon/3a1691162077971.63cfe5a645dff.png')} alt="" />
                        </div>
                        <div className='section-contact pb-20 bg-teal-200 p-4 mr-6 translate-y-1/2'>
                            <p className='font-ibmplex-mono text-xs'>WHAT DID YOU THINK?</p>
                            <h4 className='font-recoleta tracking-wide font-normal mt-12'>Liked what you saw?</h4>
                            <Link to="/about">
                                <h4 className='font-recoleta tracking-wide font-normal underline underline-offset-4 decoration-1 mt-2'>Hit me up.</h4>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='w-full mt-44'>
                    
                </div>
            </div>
       </>
    )
}
