import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './projectpage.css'

export default function ProjectPage() {
    const mounted = useRef(true)

    useEffect(() => {
        mounted.current = true

        return () => {
            mounted.current = false
        }
    })
    
    return (
       <>
            <div className='pp-main-container'>
                <div className='h-96 md:h-[640px] grid'>
                    <img className='col-start-1 col-span-1 row-start-1 row-span-1 h-auto w-full overflow-hidden object-fill object-bottom' src={require('../../Assets/Images/The Two Headed Raven/2HR-1.png')} alt="" />
                    <div className='col-start-1 col-span-1 row-start-1 row-span-1 bg-gradient-to-b from-black via-transparent to-black opacity-25'></div>
                </div>
                <div className='default-main'>
                    <div className='lg:ml-6 mr-6 lg:mr-96 p-4 md:pl-16 bg-teal-200 -translate-y-1/2'>
                        <h4 className='font-space font-normal'>The Two-Headed Raven</h4>
                        <p className='font-ibmplex-mono text-xs mt-4 w-1/2 uppercase'>IN COLLAB WITH FILIP G., DESEADA P., AND FREDRIK N.</p>
                    </div>
                    <div className='flex flex-col'>
                        <div className='max-w-5xl md:grid md:grid-cols-2 justify-start self-center my-36'>
                            <div className='section-header p-4 flex flex-col'>
                                <h1 className='font-recoleta text-5xl tracking-wide border-l border-l-[#16202E] pl-3'>Explore Culture.</h1>
                                <p className='font-ibmplex-mono text-xs mt-4 w-1/3 uppercase'>VISUAL STYLE GUIDE & BRAND CONTENT</p>
                            </div>
                            <div className='section-body p-4'>
                                <p className='font-space text-base md:text-xl'>The Two-Headed Raven is an imaginary restaurant founded by 2 close friends who wants to open up their own take-away restaurant. Their aim to make great quality food from every corner of the world for people to enjoy with their friends and family.</p>
                            </div>
                        </div>
                        <div className='section-img my-10 flex flex-col py-36 bg-[#16202E]'>
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-1.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-3.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-4.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-5.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-6.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-7.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-8.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-9.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-10.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-11.png')} alt="" />
                            <img src={require('../../Assets/Images/The Two Headed Raven/2HR-12.png')} alt="" />
                        </div>
                        <div className='section-contact pb-20 bg-teal-200 p-4 mr-6 translate-y-1/2'>
                            <p className='font-ibmplex-mono text-xs'>WHAT DID YOU THINK?</p>
                            <h4 className='font-recoleta tracking-wide font-normal mt-12'>Liked what you saw?</h4>
                            <Link to="/about">
                                <h4 className='font-recoleta tracking-wide font-normal underline underline-offset-4 decoration-1 mt-2'>Hit me up.</h4>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='w-full mt-44'>
                    
                </div>
            </div>
       </>
    )
}
